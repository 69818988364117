import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setState } from "../../../reducer/RootReducer";

import { LogoPage } from "../../components/LogoPage";
import { createAccount } from "../../pages/create-account/actions/CreateAccountActions";
import { ErrorPropType } from "../../shared/models/error";

import "./CreateAccountPage.css";

export const CreateAccountPage = ({
  onFormSubmit,
  onFormChange,
  newPasswords,
  errors,
  getLocation = () => location,
}) => {
  const params = new URLSearchParams(getLocation().search);
  const email = params.get("email");
  const token = params.get("token");

  const onChange = (fieldName, fieldValue) =>
    onFormChange(`CreateAccountPage.form.${fieldName}`, fieldValue);
  const onSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(email, newPasswords[0], token);
  };

  const passwordMismatchError = newPasswords[0] !== newPasswords[1];

  return (
    <LogoPage className="create-account-page">
      <div className="create-account-page__welcome-text">
        Let&apos;s set a password for your new account.
      </div>
      <form
        onSubmit={onSubmit}
        className="stretch-horizontal"
        disabled={passwordMismatchError}
      >
        <input
          placeholder="Enter your password."
          className="create-account-page__password"
          onChange={(e) => onChange("password", e.target.value)}
          required
          type="password"
          value={newPasswords[0]}
        />
        <input
          placeholder="Confirm your password."
          className="create-account-page__password"
          onChange={(e) => onChange("confirmPassword", e.target.value)}
          required
          type="password"
          value={newPasswords[1]}
        />
        {passwordMismatchError && (
          <div className="create-account-page__error">
            Both passwords should match.
          </div>
        )}
        {errors &&
          errors.map((e) => (
            <div key={e.description} className="create-account-page__error">
              {e.description}
            </div>
          ))}
        <button
          type="submit"
          className="button button--primary create-account-page__button"
          disabled={passwordMismatchError}
        >
          Create account
        </button>
      </form>
    </LogoPage>
  );
};

CreateAccountPage.propTypes = {
  errors: PropTypes.arrayOf(ErrorPropType),
  getLocation: PropTypes.func,
  newPasswords: PropTypes.arrayOf(PropTypes.string),
  onFormChange: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  newPasswords: [
    state.CreateAccountPage.form.password,
    state.CreateAccountPage.form.confirmPassword,
  ],
  errors: state.CreateAccountPage.form.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFormSubmit: createAccount,
      onFormChange: setState,
    },
    dispatch
  );

export const CreateAccountPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountPage);
