import compareVersions from "compare-versions";
import { createSelector } from "reselect";

export const getReleases = (state) => state.releases.releases;

export const getLatestRelease = createSelector(
  [getReleases],
  (releases) => releases[0]
);

export const getLatestViewedRelease = (state) =>
  state.releases.latestViewedRelease;

export const getHasViewedLatestRelease = createSelector(
  [getLatestRelease, getLatestViewedRelease],
  (latestRelease, latestViewedRelease) => {
    return compareVersions(latestViewedRelease, latestRelease.version) === 0;
  }
);
