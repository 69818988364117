import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import notFoundImage from "../../../images/404.png";

import "./NotFoundPage.css";

export const NotFoundPage = ({ defaultRoute }) => (
  <div className="not-found-page">
    <div className="not-found-page__image">
      <img src={notFoundImage} alt="404" />
    </div>

    <div className="not-found-page__content">
      <div className="not-found-page__body">
        Sorry, this page doesn&apos;t exist. Let&apos;s help you get home!
      </div>
      <Link
        to={defaultRoute}
        className="button button--primary not-found-page__home-btn"
      >
        GO HOME
      </Link>
    </div>
  </div>
);

NotFoundPage.propTypes = {
  defaultRoute: PropTypes.string.isRequired,
};

export default NotFoundPage;
