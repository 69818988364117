import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import RoleRoute from "../../containers/RoleRoute";
import { CreateAccountPageContainer } from "../../pages/create-account/CreateAccountPage";
import NotFoundPage from "../../pages/not-found/containers/NotFoundPage";
import ReleasesPage from "../../releases/containers/ReleasesPage";
import * as Partner from "../../shared/partner/models/Partner";
import sharedPropTypes from "../../shared/propTypes";

import { getDefaultRouteForUser } from "../selectors/RouterSelectors";

const getPageRenderer = (pageKey) => {
  const PageComponent = pageImportersMap[pageKey];

  // eslint-disable-next-line react/display-name
  return (props) => <PageComponent {...props} />;
};

const pageImportersMap = {
  brand: lazy(() => import("src/app/pages/brand/containers/BrandPage")),
  brandActivity: lazy(() =>
    import("src/app/pages/brand/containers/BrandActivityPage")
  ),
  brandEdit: lazy(() => import("src/app/pages/brand/containers/BrandEditPage")),
  brandReview: lazy(() =>
    import("src/app/pages/brand/containers/BrandReviewPage")
  ),
  insights: lazy(() =>
    import("src/app/pages/insights/containers/InsightsPage")
  ),
  partner: lazy(() => import("src/app/pages/partner/containers/PartnerPage")),
  partners: lazy(() =>
    import("src/app/pages/admin/partners/containers/PartnersPage")
  ),
  newPartner: lazy(() =>
    import("src/app/pages/brand/containers/BrandEditPage")
  ),
  login: lazy(() => import("src/app/pages/login/components/LoginPage")),
  forgotPassword: lazy(() =>
    import("src/app/pages/login/components/ForgotPasswordPage")
  ),
  strain: lazy(() => import("src/app/pages/strains/containers/StrainPage")),
  strains: lazy(() => import("src/app/pages/strains/containers/StrainsPage")),
  strainEdit: lazy(() =>
    import("src/app/pages/strains/containers/EditStrainPage")
  ),
  strainReview: lazy(() =>
    import("src/app/pages/strains/containers/ReviewStrainPage")
  ),
  strainActivity: lazy(() =>
    import("src/app/pages/strains/containers/StrainActivityPage")
  ),
  forgotPasswordSuccess: lazy(() =>
    import("src/app/pages/login/components/ForgotPasswordSuccessPage")
  ),
  resetPassword: lazy(() =>
    import("src/app/pages/login/components/ResetPasswordPage")
  ),
};

const AdminRoutes = ({ match }) => (
  <Switch>
    <RoleRoute
      exact
      path={`${match.path}/partner/new`}
      component={getPageRenderer("newPartner")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners`}
      component={getPageRenderer("partners")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/home`}
      component={getPageRenderer("partner")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/brand`}
      component={getPageRenderer("brand")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/brand/activity`}
      component={getPageRenderer("brandActivity")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/brand/edit`}
      component={getPageRenderer("brandEdit")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/brand/review`}
      component={getPageRenderer("brandReview")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/insights`}
      component={getPageRenderer("insights")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains`}
      component={getPageRenderer("strains")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains/new`}
      component={getPageRenderer("strainEdit")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains/:strainId`}
      component={getPageRenderer("strain")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains/:strainId/edit`}
      component={getPageRenderer("strainEdit")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains/:strainId/review`}
      component={getPageRenderer("strainReview")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
    <RoleRoute
      exact
      path={`${match.path}/partners/:partnerId/strains/:strainId/activity`}
      component={getPageRenderer("strainActivity")}
      role={Partner.USER_ROLES.SUPER_USER}
      redirectTo={getDefaultRouteForUser}
    />
  </Switch>
);

AdminRoutes.propTypes = {
  match: sharedPropTypes.match.isRequired,
};

const PartnerRoutes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/home`}
      component={getPageRenderer("partner")}
    />
    <Route
      exact
      path={`${match.path}/brand`}
      render={getPageRenderer("brand")}
    />
    <Route
      exact
      path={`${match.path}/brand/activity`}
      render={getPageRenderer("brandActivity")}
    />
    <Route
      exact
      path={`${match.path}/brand/edit`}
      render={getPageRenderer("brandEdit")}
    />
    <Route
      exact
      path={`${match.path}/strains`}
      render={getPageRenderer("strains")}
    />
    <Route
      exact
      path={`${match.path}/insights`}
      render={getPageRenderer("insights")}
    />
    <Route
      exact
      path={`${match.path}/strains/new`}
      render={getPageRenderer("strainEdit")}
    />
    <Route
      exact
      path={`${match.path}/strains/:strainId`}
      render={getPageRenderer("strain")}
    />
    <Route
      exact
      path={`${match.path}/strains/:strainId/edit`}
      render={getPageRenderer("strainEdit")}
    />
    <Route
      exact
      path={`${match.path}/strains/:strainId/activity`}
      render={getPageRenderer("strainActivity")}
    />
  </Switch>
);

PartnerRoutes.propTypes = {
  match: sharedPropTypes.match.isRequired,
};

const Routes = () => (
  <Suspense fallback="">
    <Switch>
      <Route exact path="/404" component={NotFoundPage} />
      <Route exact path="/" render={getPageRenderer("login")} />
      <Route exact path="/login" render={getPageRenderer("login")} />
      <Route
        exact
        path="/forgot-password"
        render={getPageRenderer("forgotPassword")}
      />
      <Route
        exact
        path="/forgot-password-success"
        component={getPageRenderer("forgotPasswordSuccess")}
      />
      <Route
        exact
        path="/reset-password"
        component={getPageRenderer("resetPassword")}
      />
      <Route
        exact
        path="/verify-email"
        component={CreateAccountPageContainer}
      />

      <RoleRoute
        path="/partner"
        component={PartnerRoutes}
        role={Partner.USER_ROLES.ADMINISTRATOR}
        redirectTo={getDefaultRouteForUser}
      />
      <RoleRoute
        path="/admin"
        component={AdminRoutes}
        role={Partner.USER_ROLES.SUPER_USER}
        redirectTo={getDefaultRouteForUser}
      />
      <RoleRoute
        path="/releases/:version?"
        component={ReleasesPage}
        roles={[
          Partner.USER_ROLES.ADMINISTRATOR,
          Partner.USER_ROLES.SUPER_USER,
        ]}
        redirectTo={getDefaultRouteForUser}
      />

      <Route component={NotFoundPage} />
    </Switch>
  </Suspense>
);

export default Routes;
