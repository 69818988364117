import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FaChevronDown, FaExclamationCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import { extractInitials } from "../../Utils";

import { ProfileOptionsMenu } from "../components/ProfileOptionsMenu";
import PaxConnectLogo from "../images/pax-connect-logo.svg";
import { MODERATION_STATUS_REJECTED } from "../shared/moderation/models/Moderation";
import { PartnerPropType } from "../shared/partner/models/Partner";
import sharedPropTypes from "../shared/propTypes";

import "./HeaderBar.css";

const HeaderBarMenuItems = ({ email, onLogout, partnerName }) => {
  return (
    <>
      {partnerName && (
        <div
          className="header-bar-menu__partner-name"
          data-testid="partner-name"
        >
          {partnerName}
        </div>
      )}
      <div className="header-bar-menu__email">{email}</div>
      <hr className="header-bar-menu__hr" />
      <a onClick={onLogout} className="header-bar-menu__link logout">
        <FiLogOut className="logout__icon" />
        Logout
      </a>
    </>
  );
};

HeaderBarMenuItems.propTypes = {
  email: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

const HeaderBar = ({
  fetchPartner,
  location,
  onLogout,
  partner,
  partnerId,
  profileInfo,
  routes,
}) => {
  useEffect(() => {
    if (partnerId) {
      fetchPartner(partnerId);
    }
  }, [fetchPartner, partnerId]);

  if (!profileInfo.email) {
    return null;
  }

  const logo = (partner && partner.logo) || {};
  const logoUrl = logo.downloadUrl || "";

  const menuItems = (
    <HeaderBarMenuItems
      email={profileInfo.email}
      onLogout={onLogout}
      partnerName={profileInfo.partnerName}
      routes={routes}
    />
  );
  const partnerInitials = extractInitials(profileInfo.partnerName);

  return (
    <div className="header-bar">
      <div className="header-bar__left">
        <Link to={routes.default()}>
          <PaxConnectLogo className="header-bar__logo" />
        </Link>
      </div>
      <div className="header-bar__links">
        {!partnerId && (
          <NavLink
            className="header-bar__link"
            data-testid="header-bar-home-link"
            to={routes.partners()}
          >
            Home
          </NavLink>
        )}

        {partnerId && (
          <>
            <NavLink
              className="header-bar__link"
              data-testid="header-bar-home-link"
              to={routes.partner(partnerId)}
            >
              Home
            </NavLink>
            <NavLink
              className="header-bar__link"
              data-testid="header-bar-strains-link"
              to={routes.strains(partnerId)}
            >
              Strains
            </NavLink>
            <NavLink
              className="header-bar__link"
              data-testid="header-bar-brands-link"
              to={routes.brand(partnerId)}
            >
              {partner &&
                partner.moderationState.status ===
                  MODERATION_STATUS_REJECTED && (
                  <FaExclamationCircle className="rejected" />
                )}
              Brand Story
            </NavLink>
            {profileInfo.canAccessBpInsights &&
              partner &&
              partner.bpInsightsUrl && (
                <NavLink
                  className="header-bar__link"
                  data-testid="header-bar-bp-insights-link"
                  to={routes.insights(partnerId)}
                >
                  Insights - Beta
                </NavLink>
              )}
          </>
        )}
        <NavLink
          className="header-bar__link"
          data-testid="header-bar-whats-new-link"
          to={routes.releases()}
        >
          <span>What&apos;s New</span>
        </NavLink>
      </div>
      <div className="header-bar__profile-menu">
        <ProfileOptionsMenu menuItems={menuItems} location={location}>
          <div
            className="header-bar__profile-menu-toggle"
            data-testid="header-bar-menu-toggle"
          >
            <sc.PartnerIcon data-testid="header-bar-icon" logoUrl={logoUrl}>
              {!logoUrl && partnerInitials}
            </sc.PartnerIcon>
            <div className="header-bar__my-account">
              {profileInfo.firstName + " " + profileInfo.lastName}
              <FaChevronDown className="dropdown__icon" />
            </div>
          </div>
        </ProfileOptionsMenu>
      </div>
    </div>
  );
};

HeaderBar.propTypes = {
  fetchPartner: PropTypes.func.isRequired,
  location: sharedPropTypes.location,
  onLogout: PropTypes.func.isRequired,
  partner: PartnerPropType,
  partnerId: PropTypes.string,
  profileInfo: sharedPropTypes.profileInfo,
  routes: sharedPropTypes.routes,
};

export default HeaderBar;

const sc = {
  PartnerIcon: styled.div`
    align-items: center;
    background: linear-gradient(
      to right,
      var(--golden-yellow),
      var(--orange-red)
    );
    background-image: ${({ logoUrl }) => (logoUrl ? `url(${logoUrl})` : "")};
    background-size: 34px 34px;
    border: none;
    border-radius: 100%;
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: var(--gungan);
    font-weight: 500;
    height: 34px;
    justify-content: center;
    min-width: 34px;
    outline: none;
    position: relative;
    width: 34px;
  `,
};
