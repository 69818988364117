import reduceReducers from "reduce-reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { initializeApp } from "./app/AppActions";
import releasesReducer from "./app/releases/reducers";
import { initialState } from "./reducer/InitialState";
import { reducer as rootReducer } from "./reducer/RootReducer";

export const store = createStore(
  reduceReducers(initialState, rootReducer, releasesReducer),
  composeWithDevTools(applyMiddleware(thunk))
);

store.dispatch(initializeApp(location.hash.substr(1)));
