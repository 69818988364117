import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as UserActions from "../../app/pages/login/actions/UserActions";
import * as RouterSelectors from "../../app/router/selectors/RouterSelectors";
import * as PartnerActions from "../../app/shared/partner/actions/PartnerActions";

import HeaderBar from "../components/HeaderBar";

const mapStateToProps = (state) => {
  return {
    partner: RouterSelectors.getCurrentPartner(state),
    partnerId: RouterSelectors.getCurrentPartnerId(state),
    profileInfo: state.profileInfo,
    routes: RouterSelectors.getRoutes(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPartner: PartnerActions.fetchPartner,
      onLogout: UserActions.logout,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
);
