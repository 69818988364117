import releases from "../app/releases/data";
import { getEmptyModerationActivity } from "../app/shared/moderation/models/Moderation";
import * as Partner from "../app/shared/partner/models/Partner";

export const initialState = {
  CreateAccountPage: {
    form: {
      password: "",
      confirmPassword: "",
      errors: [],
    },
  },
  PartnerPage: {
    editMode: false,
    newUserForm: Partner.getEmptyNewUserForm(),
    newUserMode: false,
    partnerModerationActivity: [],
  },
  StrainPage: {
    pagination: {},
  },
  StrainsPage: {
    editMode: false,
    isSelectingStrain: false,
    selectedStrainActivity: [],
    selectedStrainBatches: [],
    selectedStrainId: null,
    strainModeration: getEmptyModerationActivity(),
  },
  blobs: {},
  flashMessage: null,
  geoStates: {},
  impersonatedPartnerId: null,
  isFetchingUserInfo: false,
  partnerInvitations: Partner.getEmptyPartnerUsers(),
  partnerModeration: getEmptyModerationActivity(),
  partnerUsers: Partner.getEmptyPartnerUsers(),
  partners: {},
  partnersFilter: {
    geoState: "",
    region: "",
    subset: "Active",
  },
  partnerStrains: {},
  partnerTables: {
    inactionablePartners: { asc: true, sortBy: null },
    actionablePartners: { asc: true, sortBy: null },
  },
  profileInfo: {
    email: "",
    firstName: "",
    lastName: "",
    partnerId: "",
    partnerName: "",
    passwordHash: "",
    role: "",
  },
  ratings: [],
  releases: {
    latestViewedRelease: "0.0.0",
    releases,
  },
  sessionToken: null,
  strains: {
    pagination: {},
  },
  strainsFilter: {},
  strainTables: {
    archivedStrains: { asc: true, sortBy: null },
    moderationStrains: { asc: true, sortBy: null },
    strains: { asc: true, sortBy: null },
  },
};
