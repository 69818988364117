import { ACCESS_TOKEN, REDIRECTABLE_ROUTES } from "../constants";
import { setState } from "../reducer/RootReducer";
import { fetchUserInfo } from "./pages/login/actions/UserActions";

export const initializeApp = (currentRoute) => (dispatch) => {
  const jwtToken = localStorage.getItem(ACCESS_TOKEN);
  if (!jwtToken) {
    return;
  }

  dispatch(setState("sessionToken", jwtToken));

  const redirectToDefaultRoute =
    REDIRECTABLE_ROUTES.indexOf(currentRoute) !== -1;
  dispatch(fetchUserInfo({ redirectToDefaultRoute }));
};
