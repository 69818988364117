import styled from "styled-components";

const SidebarList = styled.ul`
  flex: 1;
  margin: 40px 0 40px 0;
  padding: 0 0 150px;
`;

SidebarList.ListItem = styled.li`
  align-items: center;
  border: 1px solid transparent;
  color: var(--black);
  cursor: pointer;
  display: flex;
  height: 44px;
  list-style: none;
  margin: 0 6px 10px 0;
  padding-left: 20px;
  position: relative;
`;

SidebarList.ListItem.Title = styled.span`
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

SidebarList.ListItem.Subtitle = styled.span`
  color: var(--brownish-grey-two);
  font-size: 18px;
  letter-spacing: 0.75px;
  line-height: 27px;
  margin-left: 9px;
`;

export default SidebarList;
