import { connect } from "react-redux";

import * as PartnerSelectors from "../../shared/partner/selectors/PartnerSelectors";

import { postViewedRelease } from "../actions/ReleasesActions";
import NewReleaseModal from "../components/NewReleaseModal";
import * as ReleasesSelectors from "../selectors/ReleasesSelectors";

const mapStateToProps = (state) => {
  const isFetchingUserInfo =
    PartnerSelectors.getIsFetchingPartnerUserInfo(state);
  const isPartnerUserSignedIn =
    PartnerSelectors.getIsPartnerUserSignedIn(state);
  const hasViewedLatestRelease =
    ReleasesSelectors.getHasViewedLatestRelease(state);

  return {
    isOpen:
      !hasViewedLatestRelease && !isFetchingUserInfo && isPartnerUserSignedIn,
    latestRelease: ReleasesSelectors.getLatestRelease(state),
  };
};

const mapDispatchToProps = {
  postViewedRelease,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewReleaseModal);
