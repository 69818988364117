import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import sharedPropTypes from "../shared/propTypes";

import "./Badge.css";

const Badge = ({ children, className, ...props }) => {
  return (
    <div className={cn("badge", className)} {...props}>
      {children}
    </div>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
};

export default Badge;
