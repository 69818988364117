import apiRequest from "../../../../app/api/apiRequest";
import bugsnagClient from "../../../../bugsnag";
import { hashifyArray, setState } from "../../../../reducer/RootReducer";

import { SAVE_VERBS } from "../../../shared/constants";
import {
  flashError,
  flashInfo,
} from "../../../shared/flash/actions/FlashActions";

import { getEmptyNewUserForm } from "../models/Partner";

const PARTNER_PAGE = "PartnerPage";

export const setPartnerPageEditMode =
  (toggle, page = PARTNER_PAGE) =>
  (dispatch) =>
    dispatch(setState(`${page}.editMode`, toggle));

export const fetchPartners =
  ({ page = 1, pageSize = 200 } = {}) =>
  async (dispatch) => {
    const response = await apiRequest({
      cache: true,
      dispatch,
      url: `/api/v1/partners?page=${page}&page_size=${pageSize}`,
    });

    if (!response.ok) {
      return dispatch(
        flashError("Error fetching partners. Please reload and try again.")
      );
    }

    const partners = response.data.results;
    dispatch(setState("partners", hashifyArray(partners)));

    return response;
  };

export const fetchPartner = (partnerId) => async (dispatch) => {
  const response = await apiRequest({
    cache: true,
    dispatch,
    url: `/api/v1/partners/${partnerId}/`,
  });

  if (!response.ok) {
    return dispatch(
      flashError("Error fetching partner. Please reload and try again.")
    );
  }

  const partner = response.data;
  dispatch(setState(`partners.${partner.id}`, partner));

  return response;
};

export const savePartner =
  (partner, saveVerb = SAVE_VERBS.SAVE) =>
  async (dispatch) => {
    let url = "/api/v1/partners";
    if (partner.id) {
      url += `/${partner.id}`;
    }

    const requestData = {
      data: JSON.stringify(partner),
      dispatch,
      method: partner.id ? "PUT" : "POST",
      url,
    };
    const response = await apiRequest(requestData);

    const { data, ok } = response;
    if (ok) {
      dispatch(setState(`partners.${partner.id}`, partner));
      dispatch(flashInfo(`Partner ${saveVerb.PAST} successfully!`));
    } else {
      if (data.errorCode === "DUPLICATE_OBJECT") {
        data.errors = [
          "A partner with this name or primary contact already exists.",
        ];
      } else {
        dispatch(
          setState("flashMessage", {
            message: `Error ${saveVerb.PRESENT} partner! Please try again.`,
            type: "error",
          })
        );
      }
    }

    return response;
  };

export const archivePartner = (partner, isArchived = true) => {
  return savePartner(
    { ...partner, isArchived },
    isArchived ? SAVE_VERBS.ARCHIVE : SAVE_VERBS.UNARCHIVE
  );
};

export const handlePartnerFormCancel = () => (dispatch) =>
  dispatch(setPartnerPageEditMode(false));

export const handlePartnerFormSave = (partner) => async (dispatch) => {
  const response = await dispatch(savePartner(partner));

  if (response.ok) {
    dispatch(flashInfo("Account information saved successfully!"));
    dispatch(setPartnerPageEditMode(false));
  } else {
    dispatch(flashError("Error saving account information!"));
  }

  return response;
};

export const handleLogoChange = (partner, logo) => (dispatch) => {
  dispatch(setState(`partners.${partner.id}.logo`, logo));
};

export const fetchPartnerUsers = (partnerId) => async (dispatch) => {
  const response = await apiRequest({
    cache: true,
    dispatch,
    url: `/api/v1/partners/${partnerId}/users?page=1&page_size=100`,
  });

  if (!response.ok) {
    return dispatch(
      flashError("Error fetching partner users. Please reload to try again.")
    );
  }

  dispatch(setState("partnerUsers", response.data.results));
};

export const fetchPartnerInvitations = (partnerId) => async (dispatch) => {
  const response = await apiRequest({
    cache: true,
    dispatch,
    url: `/api/v1/partners/${partnerId}/invitations?page=1&page_size=25`,
  });

  if (!response.ok) {
    return dispatch(
      flashError(
        "Error fetching partner invitations. Please reload to try again."
      )
    );
  }

  dispatch(setState("partnerInvitations", response.data.results));
};

export const handleAddUser =
  (page = PARTNER_PAGE) =>
  (dispatch) => {
    dispatch(setState(`${page}.newUserMode`, true));
  };

export const toggleNewUserMode =
  (toggle, page = PARTNER_PAGE) =>
  (dispatch) => {
    dispatch(setState(`${page}.newUserMode`, toggle));
  };

export const handleEditUser =
  (partnerUser, page = PARTNER_PAGE) =>
  (dispatch) => {
    dispatch(setState(`${page}.newUserMode`, false));
    dispatch(
      setState(`${page}.editUserForm`, {
        ...partnerUser,
        originalEmail: partnerUser.email,
      })
    );
  };

export const handleEditUserFormChange =
  (key, value, page = PARTNER_PAGE) =>
  (dispatch) =>
    dispatch(setState(`${page}.editUserForm.${key}`, value));

export const handleEditUserFormCancel =
  (page = PARTNER_PAGE) =>
  (dispatch) =>
    dispatch(setState(`${page}.editUserForm`, null));

export const handleEditUserFormSubmit =
  (user, page = PARTNER_PAGE) =>
  async (dispatch, getState) => {
    const { email, firstName, lastName, role } = user;

    const response = await apiRequest({
      data: { email, firstName, lastName, role },
      dispatch,
      method: "PUT",
      url: `/api/v1/partners/${user.partnerId}/users/${user.originalEmail}`,
    });

    if (!response.ok) {
      return dispatch(flashError("Error updating user. Please try again."));
    }

    const { partnerUsers } = getState();
    const userIndex = partnerUsers.findIndex(
      (u) => u.email === user.originalEmail
    );

    dispatch(setState(`${page}.editUserForm`, null));
    dispatch(setState(`partnerUsers.${userIndex}`, user));
    dispatch(
      setState("flashMessage", {
        message: "Successfully updated user.",
        type: "info",
      })
    );
  };

export const handleDeleteUser = (deletedUser) => async (dispatch, getState) => {
  const response = await apiRequest({
    dispatch,
    method: "DELETE",
    url: `/api/v1/partners/${deletedUser.partnerId}/users/${deletedUser.email}`,
  });

  if (!response.ok) {
    dispatch(flashError("Error deleting user. Please try again."));
    return;
  }

  const { partnerUsers } = getState();
  const newPartnerUsers = partnerUsers.filter(
    (user) => user.email != deletedUser.email
  );

  dispatch(setState("partnerUsers", newPartnerUsers));
  dispatch(flashInfo("Successfully deleted user."));
};

export const handleNewUserFormInputChange =
  (key, value, page = PARTNER_PAGE) =>
  (dispatch) => {
    dispatch(setState(`${page}.newUserForm.${key}`, value));
  };

export const handleNewUserFormCancel =
  (page = PARTNER_PAGE) =>
  (dispatch) => {
    dispatch(setState(`${page}.newUserMode`, false));
    dispatch(setState(`${page}.newUserForm`, getEmptyNewUserForm()));
  };

export const createPartnerUserInvitation =
  (partnerId, newUser, page = PARTNER_PAGE) =>
  async (dispatch, getState) => {
    const response = await apiRequest({
      dispatch,
      url: `/api/v1/partners/${partnerId}/invitations`,
      method: "POST",
      data: newUser,
    });

    if (!response.ok) {
      dispatch(
        setState(
          `${page}.newUserForm.errors`,
          (response.data.errors || ["Error inviting user"]).map(
            (description) => ({
              description,
            })
          )
        )
      );
      return;
    }

    const { partnerInvitations } = getState();
    const newInvitations = [...partnerInvitations, newUser];

    dispatch(setState(`${page}.newUserMode`, false));
    dispatch(setState(`${page}.newUserForm`, getEmptyNewUserForm()));
    dispatch(setState("partnerInvitations", newInvitations));

    return response;
  };

export const handleResendInvitation =
  (partnerId, invitation) => async (dispatch) => {
    const url = `/api/v1/partners/${partnerId}/invitations`;

    const requestData = {
      data: JSON.stringify(invitation),
      dispatch,
      method: "POST",
      url,
    };
    const response = await apiRequest(requestData);

    if (response.ok) {
      dispatch(flashInfo("Successfully resent invitation."));
    } else {
      dispatch(flashError("Error resending invitation. Please try again."));
    }
  };

export const handleDeleteInvitation =
  (deletedInvitation, partnerId) => async (dispatch, getState) => {
    const response = await apiRequest({
      dispatch,
      method: "DELETE",
      url: `/api/v1/partners/${partnerId}/invitations/${deletedInvitation.email}`,
    });

    if (!response.ok) {
      dispatch(flashError("Error deleting invitation. Please try again."));
      return;
    }

    const { partnerInvitations } = getState();
    const newInvitations = partnerInvitations.filter(
      (invitation) => invitation.email != deletedInvitation.email
    );

    dispatch(setState("partnerInvitations", newInvitations));
    dispatch(flashInfo("Successfully deleted invitation."));
  };

export const createSubmission = (partner) => async (dispatch) => {
  const url = `/api/v1/partners/${partner.id}/submissions`;

  const response = await apiRequest({ dispatch, method: "POST", url });

  const { data, ok } = response;
  if (ok) {
    dispatch(setState(`partners.${partner.id}.moderationState`, data));

    dispatch(flashInfo("Submission created successfully!"));
  } else {
    bugsnagClient.notify(
      new Error("Unexpected error creating partner submission")
    );

    dispatch(flashError("Error creating submission!"));
  }

  return response;
};

export const setPartnerModeration = (partnerModeration) => (dispatch) =>
  dispatch(setState("partnerModeration", partnerModeration));
