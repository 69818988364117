import {
  arrayOf,
  bool,
  func,
  instanceOf,
  node,
  number,
  objectOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";

const sharedPropTypes = {
  children: oneOfType([arrayOf(node), node]),
  history: ReactRouterPropTypes.history,
  match: ReactRouterPropTypes.match,
  optionalNumber: oneOfType([number, string]),
  paginationParams: shape({
    archived: bool.isRequired,
    moderationPage: number,
    moderationPageSize: number,
    page: number.isRequired,
    pageSize: number.isRequired,
  }),
  photo: shape({
    downloadUrl: string,
    fileId: string,
  }),
  profileInfo: shape({
    email: string,
    firstName: string,
    lastName: string,
    partnerId: string,
    partnerName: string,
    passwordHash: string,
    role: string,
  }),
  queryParams: shape({
    archived: bool,
    moderationPage: number,
    page: number,
    pageSize: number,
  }),
  ref: oneOfType([func, shape({ current: instanceOf(Element) })]),
  routes: objectOf(func),
  location: ReactRouterPropTypes.location,
  rejectedFieldsMap: objectOf(Boolean),
  video: shape({
    downloadUrl: string,
    fileId: string,
    name: string,
  }),
};

export default sharedPropTypes;
