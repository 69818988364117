import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import sharedPropTypes from "../shared/propTypes";

import "./ProfileOptionsMenu.css";

export const ProfileOptionsMenu = ({ children, location, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const closeMenu = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", closeMenu);

    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleMenuToggleClick = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderMenuDropdown = () => {
    return (
      <div ref={dropdownRef} className="profile-options-menu__items">
        {menuItems}
      </div>
    );
  };

  return (
    <div className="profile-options-menu">
      <button
        className="profile-options-menu__button"
        type="button"
        onClick={handleMenuToggleClick}
      >
        {children}
      </button>
      {isOpen && renderMenuDropdown()}
    </div>
  );
};

ProfileOptionsMenu.propTypes = {
  children: sharedPropTypes.children,
  location: sharedPropTypes.location,
  menuItems: PropTypes.node,
};
