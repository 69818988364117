import { createSelector } from "reselect";

import { MODERATION_ACTIVITY_TYPE } from "../../../shared/moderation/models/ModerationActivity";

const { APPROVE, COMMENT, REJECT, SUBMIT } = MODERATION_ACTIVITY_TYPE;
const RENDERABLE_ACTIVITY_TYPES = [APPROVE, COMMENT, REJECT, SUBMIT];

const shouldRenderActivity = (activity) => {
  return RENDERABLE_ACTIVITY_TYPES.indexOf(activity.type) >= 0;
};

export const createModerationActivitySelector = (getModerationActivity) => {
  return createSelector(getModerationActivity, (activities) => {
    return activities.filter(shouldRenderActivity);
  });
};
