import { createHashHistory } from "history";

const history = createHashHistory();

// analytics is not enabled when running locally
if (window.analytics) {
  history.listen((location) => {
    const path = location.pathname;
    window.analytics.page(path, { path });
  });
}

export default history;
