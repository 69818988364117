import { PUBLIC_ROUTES } from "src/constants";
import history from "src/history";

import { setState } from "../../../../reducer/RootReducer";

import apiRequest from "../../../api/apiRequest";

export const createAccount =
  (email, password, verificationCode) => async (dispatch) => {
    const response = await apiRequest({
      data: { email: decodeURIComponent(email), password, verificationCode },
      dispatch,
      method: "POST",
      url: "/api/v1/partner-users",
    });

    if (!response.ok) {
      dispatch(
        setState(
          "CreateAccountPage.form.errors",
          response.data.errors.map((description) => {
            const errorMessage =
              description === "Invalid token."
                ? "This invitation is expired. Please contact your administrator to receive a fresh invite."
                : description;

            return { description: errorMessage };
          })
        )
      );

      return response;
    }

    dispatch(setState("CreateAccountPage.form.errors", []));
    dispatch(
      setState("flashMessage", {
        message: "You successfully created your account!",
        type: "info",
      })
    );
    dispatch(
      setState("CreateAccountPage.form", {
        password: "",
        confirmPassword: "",
      })
    );
    history.push(PUBLIC_ROUTES.login);

    return response;
  };
