import React, { Suspense } from "react";
import { Route } from "react-router-dom";

import HeaderBar from "../containers/HeaderBar";
import sharedPropTypes from "../shared/propTypes";
import { ImpersonationBarContainer } from "./admin/ImpersonationBar";
import { FlashMessageContainer } from "./FlashMessage";

import "./Layout.css";

export const Layout = ({ children }) => {
  return (
    <Suspense fallback="">
      <div className="layout">
        <Route
          path="/admin/partners/:partnerId"
          component={ImpersonationBarContainer}
        />

        <FlashMessageContainer />
        <HeaderBar />
        {children}
      </div>
    </Suspense>
  );
};

Layout.propTypes = {
  children: sharedPropTypes.children,
};
