import { setState } from "src/reducer/RootReducer";

export const flashError = (message) =>
  setState("flashMessage", { message, type: "error" });

export const flashInfo = (message) =>
  setState("flashMessage", { message, type: "info" });

export const flashWarning = (message) =>
  setState("flashMessage", { message, type: "warning" });

export const flashMessage = ({ message, type }) =>
  setState("flashMessage", { message, type });
