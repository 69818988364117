import apiRequest from "../../api/apiRequest";

import * as selectors from "../selectors/ReleasesSelectors";

export const VIEWED_RELEASE = "VIEWED_RELEASE";

export const fetchHasViewedLatestRelease = () => async (dispatch, getState) => {
  const latestRelease = selectors.getLatestRelease(getState());

  const response = await apiRequest({
    baseURL: CONSUMER_SERVICE_URL,
    dispatch,
    url: `api/v1/announcements/${latestRelease.version}`,
  });

  if (response.ok && response.data.viewed === true) {
    dispatch({
      type: VIEWED_RELEASE,
      data: { latestViewedRelease: latestRelease.version },
    });
  }
};

export const postViewedRelease = (version) => async (dispatch) => {
  await apiRequest({
    baseURL: CONSUMER_SERVICE_URL,
    dispatch,
    method: "POST",
    url: `api/v1/announcements/${version}/views`,
  });

  dispatch({
    type: VIEWED_RELEASE,
    data: { latestViewedRelease: version },
  });
};
