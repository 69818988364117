import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

import "src/polyfills";

import App from "./app/App";

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));
