import LRUCache from "lru-cache";

const fetchCache = new LRUCache({
  length: (item) => item.length,
  maxAge: 2 * 60 * 1000,
});

export default fetchCache;

export const clearKeysForApiPath = (path) => {
  const pathsToClear = getPathsToClearForApiPath(path);
  const keysToClear = [];

  fetchCache.forEach((_, key) => {
    pathsToClear.forEach((pathToClear) => {
      if (key === pathToClear || key.startsWith(pathToClear + "?")) {
        keysToClear.push(key);
      }
    });
  });

  keysToClear.forEach((key) => fetchCache.del(key));
};

const getPathsToClearForApiPath = (path) => {
  path = standardizeApiPath(path);

  const parts = path.split("/");
  const baseUrl = parts.slice(0, 3).join("/");
  const resourceParts = parts.slice(3);

  if (resourceParts.length % 2 === 0) {
    return [
      baseUrl +
        "/" +
        resourceParts.slice(0, resourceParts.length - 1).join("/"),
      baseUrl + "/" + resourceParts.join("/"),
    ];
  } else {
    return [path];
  }
};

export const standardizeApiPath = (path) => {
  const url = new URL(path, "http://url.base");

  if (!url.pathname.startsWith("/")) {
    path = "/" + path;
  }

  if (url.pathname.endsWith("/")) {
    path = url.pathname.slice(0, url.pathname.length - 1) + url.search;
  }

  return path;
};
