// In reverse chronological order; most recent first.
export default [
  {
    body: require("./2021-10-19-1.3.0").default,
    date: "2021-10-19",
    version: "1.3.0",
  },
  {
    body: require("./2020-01-07-1.2.0").default,
    date: "2020-01-07",
    version: "1.2.0",
  },
  {
    body: require("./2019-12-11-1.1.9").default,
    date: "2019-12-11",
    version: "1.1.9",
  },
  {
    body: require("./2019-12-11-1.1.8").default,
    date: "2019-12-11",
    version: "1.1.8",
  },
  {
    body: require("./2019-12-04-1.1.7").default,
    date: "2019-12-04",
    version: "1.1.7",
  },
  {
    body: require("./2019-11-25-1.1.6").default,
    date: "2019-11-25",
    version: "1.1.6",
  },
];
