import PropTypes from "prop-types";
import React from "react";

import sharedPropTypes from "../shared/propTypes";
import { Modal } from "./Modal";

const WithUserInfo = ({ isFetchingUserInfo, children }) => {
  if (isFetchingUserInfo) {
    return (
      <Modal className="modal--small" isOpen={true} showCloseIcon={false}>
        Fetching user info...
      </Modal>
    );
  }

  return children;
};

WithUserInfo.propTypes = {
  children: sharedPropTypes.children,
  isFetchingUserInfo: PropTypes.bool.isRequired,
};

export default WithUserInfo;
