import { createSelector } from "reselect";

import { DEFAULT_PAGE_SIZES } from "../../shared/constants";
import * as Partner from "../../shared/partner/models/Partner";

const Routes = {
  admin: {
    account: (id) => `/admin/partners/${id}/account`,
    brand: (id) => `/admin/partners/${id}/brand`,
    brandActivity: (id) => `/admin/partners/${id}/brand/activity`,
    brandEdit: (id) => `/admin/partners/${id}/brand/edit`,
    brandReview: (id) => `/admin/partners/${id}/brand/review`,
    insights: (id) => `/admin/partners/${id}/insights`,
    partner: (
      id,
      pagination = {
        archived: false,
        moderationPage: 1,
        moderationPageSize: DEFAULT_PAGE_SIZES.MODERATION_PAGE_SIZE,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZES.PARTNER_PAGE_SIZE,
      }
    ) => {
      const { archived, moderationPage, moderationPageSize, page, pageSize } =
        pagination;

      return `/admin/partners/${id}/home?archived=${archived}&moderationPage=${moderationPage}&moderationPageSize=${moderationPageSize}&page=${page}&pageSize=${pageSize}`;
    },
    newPartner: () => "/admin/partner/new",
    partners: () => "/admin/partners",
    newStrain: (id) => `/admin/partners/${id}/strains/new`,
    strainEdit: (partnerId, strainId) =>
      `/admin/partners/${partnerId}/strains/${strainId}/edit`,
    strainReview: (partnerId, strainId) =>
      `/admin/partners/${partnerId}/strains/${strainId}/review`,
    strainActivity: (partnerId, strainId) =>
      `/admin/partners/${partnerId}/strains/${strainId}/activity`,
    strains: (
      partnerId,
      strainId,
      pagination = {
        archived: false,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZES.STRAIN_PAGE_SIZE,
      }
    ) => {
      let path = `/admin/partners/${partnerId}/strains`;

      if (strainId) {
        path += `/${strainId}`;
      }

      const { archived, page, pageSize } = pagination;
      path += `?archived=${archived}&page=${page}&pageSize=${pageSize}`;

      return path;
    },
    release: (version) => `/releases/${version}`,
    releases: () => `/releases`,

    default: () => "/admin/partners",
  },
  partner: {
    account: () => "/partner/account",
    brand: () => "/partner/brand",
    brandActivity: () => "/partner/brand/activity",
    brandEdit: () => "/partner/brand/edit",
    brandReview: () => "/partner/brand/edit",
    insights: () => "/partner/insights",
    newStrain: () => `/partner/strains/new`,
    strainEdit: (partnerId, strainId) => `/partner/strains/${strainId}/edit`,
    strainReview: (partnerId, strainId) => `/partner/strains/${strainId}/edit`,
    strainActivity: (partnerId, strainId) =>
      `/partner/strains/${strainId}/activity`,
    partner: (
      partnerId,
      pagination = {
        archived: false,
        moderationPage: 1,
        moderationPageSize: DEFAULT_PAGE_SIZES.MODERATION_PAGE_SIZE,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZES.PARTNER_PAGE_SIZE,
      }
    ) => {
      const { archived, moderationPage, moderationPageSize, page, pageSize } =
        pagination;

      return `/partner/home?archived=${archived}&moderationPage=${moderationPage}&moderationPageSize=${moderationPageSize}&page=${page}&pageSize=${pageSize}`;
    },
    release: (version) => `/releases/${version}`,
    releases: () => `/releases`,
    strains: (
      partnerId,
      strainId,
      pagination = {
        archived: false,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZES.STRAIN_PAGE_SIZE,
      }
    ) => {
      let path = `/partner/strains`;

      if (strainId) {
        path += `/${strainId}`;
      }

      const { archived, page, pageSize } = pagination;
      path += `?archived=${archived}&page=${page}&pageSize=${pageSize}`;

      return path;
    },

    default: () => "/partner/home",
  },
  public: {
    login: () => "/login",

    default: () => "/login",
  },
};

const getRoutesForUser = (profileInfo = {}) => {
  if (!profileInfo.email) {
    return Routes.public;
  }

  if (profileInfo.role === Partner.USER_ROLES.SUPER_USER) {
    return Routes.admin;
  }

  return Routes.partner;
};

export const getDefaultRouteForUser = (profileInfo) => {
  const routes = getRoutesForUser(profileInfo);
  return routes.default();
};

const getLoggedInPartnerId = (state) =>
  state.impersonatedPartnerId || state.profileInfo.partnerId;

const getCurrentRoute = () => global.location.hash.substr(1);

const getPartnerIdFromHash = createSelector(getCurrentRoute, (route) => {
  const partnerRegex = new RegExp("/partners/([-_a-zA-Z0-9]+)");
  const matches = partnerRegex.exec(route);

  if (matches) {
    return matches[1];
  }
});

export const getCurrentPartnerId = createSelector(
  getLoggedInPartnerId,
  getPartnerIdFromHash,
  (loggedInPartnerId, routePartnerId) => {
    // Return partner ids embedded in the current route.
    // Fall back to the logged-in user's partner id when the current route doesn't embed a partner id (e.g. #/login).
    return routePartnerId || loggedInPartnerId;
  }
);

export const getCurrentPartner = createSelector(
  getCurrentPartnerId,
  (state) => state.partners,
  (partnerId, partners) => {
    return partners[partnerId];
  }
);

export const getRoutes = createSelector(
  (state) => state.profileInfo,
  getRoutesForUser
);
