import { VIEWED_RELEASE } from "../actions/ReleasesActions";

const releases = (state, action) => {
  switch (action.type) {
    case VIEWED_RELEASE: {
      return {
        ...state,
        releases: {
          ...state.releases,
          latestViewedRelease: action.data.latestViewedRelease,
        },
      };
    }
    default:
      return state;
  }
};

export default releases;
