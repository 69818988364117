import PropTypes from "prop-types";
import * as yup from "yup";

import { transformIsoName } from "../../../shared/geo-state/utils/GeoStateUtils";
import * as Moderation from "../../../shared/moderation/models/Moderation";
import sharedPropTypes from "../../../shared/propTypes";

const USER_ROLE_PARTNER_ADMIN = "PARTNER_ADMIN";
const USER_ROLE_PARTNER_USER = "PARTNER_USER";
const USER_ROLE_SUPER_ADMIN = "SUPER_ADMIN";

export const USER_ROLES = {
  ADMINISTRATOR: USER_ROLE_PARTNER_ADMIN,
  OPERATOR: USER_ROLE_PARTNER_USER,
  SUPER_USER: USER_ROLE_SUPER_ADMIN,
};

export const PARTNER_ADMIN_USER_ROLES = [
  USER_ROLE_PARTNER_ADMIN,
  USER_ROLE_SUPER_ADMIN,
];

export const PARTNER_USER_ROLES = [
  USER_ROLE_PARTNER_ADMIN,
  USER_ROLE_PARTNER_USER,
];

export const SUPER_USER_ROLES = [USER_ROLE_SUPER_ADMIN];

const USER_ROLE_LABELS = {
  [USER_ROLE_PARTNER_ADMIN]: "Partner Admin",
  [USER_ROLE_PARTNER_USER]: "Partner Operator",
  [USER_ROLE_SUPER_ADMIN]: "Super User",
};

export const LINK_TYPES = {
  COMPANY_WEBSITE: "COMPANY_WEBSITE",
};

export const SUBMISSION_CONFIRMATION_MESSAGE =
  "Once approved, this brand story will be visible in the PAX Mobile App.";

export const LOGO_MAX_FILE_BYTES = 2000000; // 2MB.
export const PHOTO_MAX_FILE_BYTES = 5000000; // 5MB.
export const VIDEO_MAX_FILE_BYTES = 30000000; // 30MB.

export const LINK_TYPE_LABELS = {
  [LINK_TYPES.COMPANY_WEBSITE]: "Website",
};

export const PartnerPropType = PropTypes.shape({
  partnerName: PropTypes.string,
  partnerId: PropTypes.number,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  states: PropTypes.arrayOf(PropTypes.string),
  photos: PropTypes.arrayOf(sharedPropTypes.photo),
  primaryContact: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  videos: PropTypes.arrayOf(sharedPropTypes.video),
});

export const PartnersPropType = PropTypes.arrayOf(PartnerPropType);

export const PartnerUserPropType = PropTypes.shape({
  email: PropTypes.string,
  role: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const PartnerUsersPropType = PropTypes.arrayOf(PartnerUserPropType);

export const PartnerNewUserFormPropType = PropTypes.shape({
  email: PropTypes.string,
  role: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const getEmptyPartner = () => ({
  partnerName: "",
  links: [],
  states: [],
  moderationState: Moderation.getEmptyModerationState(),
  primaryContact: {
    email: "",
    firstName: "",
    lastName: "",
  },
});

export const getEmptyPartnerUsers = () => [];

export const getEmptyNewUserForm = () => ({
  errors: [],
  email: "",
  role: USER_ROLES.OPERATOR,
  firstName: "",
  lastName: "",
});

export const partnerRoleToString = (role) => USER_ROLE_LABELS[role];

const linkFieldNamesByLinkType = {
  [LINK_TYPES.COMPANY_WEBSITE]: "companyUrl",
};

const buildPartnerLinksMap = (partner) => {
  const partnerLinksMap = partner.links.reduce((acc, link) => {
    if (link) {
      acc[link.type] = {
        fieldName: linkFieldNamesByLinkType[link.type],
        link,
      };
    }
    return acc;
  }, {});

  Object.keys(LINK_TYPES).forEach((linkType) => {
    if (partnerLinksMap[linkType] === undefined) {
      partnerLinksMap[linkType] = {
        fieldName: linkFieldNamesByLinkType[linkType],
        link: null,
      };
    }
  });

  return partnerLinksMap;
};

export const buildPartnerFormValues = (partner) => {
  const partnerLinksMap = buildPartnerLinksMap(partner);
  const primaryContact = partner.primaryContact || {};

  let values = {
    description: partner.description,
    email: primaryContact.email,
    firstName: primaryContact.firstName,
    lastName: primaryContact.lastName,
    partnerName: partner.partnerName,
    photos: partner.photos,
    primaryContact:
      `${primaryContact.firstName} ${primaryContact.lastName}`.trim(),
    region: partner.region,
    states: partner.states.map(transformIsoName),
    videos: partner.videos,
  };

  Object.keys(LINK_TYPES).forEach((linkType) => {
    const fieldName = linkFieldNamesByLinkType[linkType];
    const link = partnerLinksMap[linkType].link;

    values[fieldName] = link ? link.url : "";
  });

  return values;
};

export const mergePartnerFormValuesIntoPartner = (partner, values) => {
  const {
    description,
    email,
    firstName,
    lastName,
    partnerName,
    photos,
    region,
    videos,
  } = values;

  let states = values.states;
  if (states[0].value) {
    states = states.map((option) => option.value);
  }

  // Maintain fixed order of links.
  const links = [LINK_TYPES.COMPANY_WEBSITE]
    .map((type) => {
      const linkFieldName = linkFieldNamesByLinkType[type];
      const url = values[linkFieldName];

      return { type, url };
    })
    .filter((link) => !!link.url);

  const updatedPartner = {
    ...partner,
    description,
    links,
    partnerName,
    photos,
    primaryContact: { email, firstName, lastName },
    region,
    states,
    videos,
  };

  return updatedPartner;
};

export const hasRole = (profileInfo, roles) => {
  if (!profileInfo) {
    return false;
  }

  return roles.indexOf(profileInfo.role) >= 0;
};

export const partnerIsInModeration = (partner) => {
  const { id, moderationState, submittedStrainIds } = partner;

  return (
    id &&
    (moderationState.status === Moderation.MODERATION_STATUS.SUBMITTED ||
      submittedStrainIds.length > 0)
  );
};

export const SUBSETS = {
  ACTIVE: "Active",
  ALL: "All Partners",
  ARCHIVED: "Archived Partners",
  NEEDS_APPROVAL: "Needs Approval",
};

export const PartnerSubsetsPropType = PropTypes.shape({
  [SUBSETS.ACTIVE]: PropTypes.arrayOf(PartnerPropType).isRequired,
  [SUBSETS.ALL]: PropTypes.arrayOf(PartnerPropType).isRequired,
  [SUBSETS.ARCHIVED]: PropTypes.arrayOf(PartnerPropType).isRequired,
  [SUBSETS.NEEDS_APPROVAL]: PropTypes.arrayOf(PartnerPropType).isRequired,
});

export const NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 2000;
export const PHOTOS_MAX_COUNT = 5;
export const VIDEOS_MAX_COUNT = 3;

export const SCHEMA = yup.object().shape({
  companyUrl: yup.string().required(""),
  description: yup.string().max(DESCRIPTION_MAX_LENGTH).required(""),
  email: yup.string().required(""),
  firstName: yup.string().required(""),
  lastName: yup.string().required(""),
  partnerName: yup.string().max(NAME_MAX_LENGTH).required(""),
  photos: yup.array().of(
    yup.object().shape({
      fileId: yup.string(),
      downloadUrl: yup.string(),
    })
  ),
  region: yup.string().required("Required."),
  states: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().nullable().required(""),
        value: yup.string().nullable().required(""),
      })
    )
    .min(1, "At least 1 state is required."),
  videos: yup.array().of(
    yup.object().shape({
      fileId: yup.string(),
      downloadUrl: yup.string(),
      name: yup.string(),
    })
  ),
});
