import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getRoutes } from "../../../app/router/selectors/RouterSelectors";
import { setState } from "../../../reducer/RootReducer";

import { fetchPartner } from "../../shared/partner/actions/PartnerActions";
import * as Partner from "../../shared/partner/models/Partner";
import { getImpersonatedPartner } from "../../shared/partner/selectors/PartnerSelectors";
import sharedPropTypes from "../../shared/propTypes";

import "./ImpersonationBar.css";

export const ImpersonationBar = ({
  fetchPartner,
  impersonatedPartner,
  match,
  routes,
  setImpersonatedPartnerId,
}) => {
  const { partnerId } = match.params;

  useEffect(() => {
    fetchPartner(partnerId);
    setImpersonatedPartnerId(partnerId);
  }, [fetchPartner, partnerId, setImpersonatedPartnerId]);

  if (!impersonatedPartner) {
    return null;
  }

  return (
    <div className="impersonation-bar">
      <div className="impersonation-bar__content">
        Impersonating {impersonatedPartner.partnerName}
      </div>
      <div>
        <Link to={routes.partners()}>
          <FaTimes className="impersonation-bar__closer" />
        </Link>
      </div>
    </div>
  );
};

ImpersonationBar.propTypes = {
  fetchPartner: PropTypes.func.isRequired,
  impersonatedPartner: Partner.PartnerPropType,
  match: sharedPropTypes.match,
  routes: sharedPropTypes.routes.isRequired,
  setImpersonatedPartnerId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  impersonatedPartner: getImpersonatedPartner(state),
  routes: getRoutes(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPartner: (id) => dispatch(fetchPartner(id)),
  setImpersonatedPartnerId: (id) =>
    dispatch(setState("impersonatedPartnerId", id)),
});

export const ImpersonationBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpersonationBar);
