export const ACCESS_TOKEN = "TOKEN";

// TODO remove in favor of app/routes
export const PUBLIC_ROUTES = {
  home: "/",
  login: "/login",
  forgotPassword: "/forgot-password",
  forgotPasswordSuccess: "/forgot-password-success",
  resetPassword: "/reset-password",
  verifyEmail: "/verify-email",
  pageNotFound: "/404",
};

export const REDIRECTABLE_ROUTES = [
  PUBLIC_ROUTES.login,
  PUBLIC_ROUTES.home,
  "#",
  "",
];
