import { connect } from "react-redux";

import { getRoutes } from "../../router/selectors/RouterSelectors";

import ReleasesPage from "../components/ReleasesPage";
import * as ReleasesSelectors from "../selectors/ReleasesSelectors";

const mapStateToProps = (state, ownProps) => {
  const releases = ReleasesSelectors.getReleases(state);
  const { version } = ownProps.match.params;

  const latestRelease = version
    ? releases.find((release) => version === release.version)
    : releases[0];

  return {
    latestRelease,
    latestViewedRelease: ReleasesSelectors.getLatestViewedRelease(state),
    releases,
    routes: getRoutes(state),
  };
};

export default connect(mapStateToProps)(ReleasesPage);
