import { formatDistanceStrict, parseISO } from "date-fns";
import filesize from "filesize";
import { isNumber } from "lodash";

export const bytesToString = (bytes) => filesize(bytes, { base: 8 });

// This function is used to convert objects values that are Numbers
// into the same shaped objects with String values.  This is used in
// our forms where we have numeric data entered but wish to be able to
// clear the fields (which would produce a value of empty string)
export const stringifyObjectValues = (obj) => {
  if (!obj) {
    return obj;
  }

  if (typeof obj === "number") {
    return String(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map(stringifyObjectValues);
  }

  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, k) => {
      if (Array.isArray(obj[k]) || typeof obj[k] === "object") {
        acc[k] = stringifyObjectValues(obj[k]);
      } else {
        acc[k] = isNumber(obj[k]) ? String(obj[k]) : obj[k];
      }
      return acc;
    }, {});
  }

  return obj;
};

export const extractInitials = (string, limit = 2) => {
  string = string || "";

  return string
    .split(" ")
    .map((s) => s[0])
    .join("")
    .substr(0, limit);
};

export const abbreviateDateWords = (string) => {
  return string
    .replace(/ seconds?/gi, "s")
    .replace(/ minutes?/gi, "m")
    .replace(/ hours?/gi, "hr")
    .replace(/ days?/gi, "d")
    .replace(/ months?/gi, "mo")
    .replace(/ years?/gi, "yr");
};

export const abbreviatedDateDistanceToNow = (dateString, now = new Date()) => {
  const date = parseISO(dateString);
  const distance = formatDistanceStrict(now, date);

  return abbreviateDateWords(distance);
};

export const optionalFieldDisplayData = (value) => {
  if (value === "" || value === 0 || !value) {
    return "N/A";
  }

  return value;
};
