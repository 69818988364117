import PropTypes from "prop-types";
import { FaEnvelopeOpen } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import styled from "styled-components";

import Badge from "../../../components/Badge";
import * as Partner from "../../../shared/partner/models/Partner";

export const MODERATION_STATUS_APPROVED = "APPROVED";
export const MODERATION_STATUS_DRAFT = "DRAFT";
export const MODERATION_STATUS_REJECTED = "REJECTED";
export const MODERATION_STATUS_SUBMITTED = "SUBMITTED";

export const MODERATION_STATUS = {
  APPROVED: MODERATION_STATUS_APPROVED,
  DRAFT: MODERATION_STATUS_DRAFT,
  REJECTED: MODERATION_STATUS_REJECTED,
  SUBMITTED: MODERATION_STATUS_SUBMITTED,
};

const sc = {
  Draft: styled(FaEnvelopeOpen)`
    color: var(--dusty-grey);
  `,

  Submitted: styled(FiClock)`
    color: var(--azure);
  `,
};

export const MODERATION_DISPLAY_MAP = {
  [MODERATION_STATUS.APPROVED]: {
    message: "Active",
    messageClassName: "",
  },
  [MODERATION_STATUS.DRAFT]: {
    Icon: sc.Draft,
    iconClassName: "moderation-status__icon--draft",
    message: "Draft",
    messageClassName: "moderation-status__message--draft",
  },
  [MODERATION_STATUS.REJECTED]: {
    Icon: Badge,
    message: "Changes Requested",
    messageClassName: "moderation-status__message--rejected",
  },
  [MODERATION_STATUS.SUBMITTED]: {
    Icon: sc.Submitted,
    iconClassName: "moderation-status__icon--submitted",
    message: "Needs Approval",
    messageClassName: "",
  },
};

export const getModerationStateDisplayData = (moderationState) => {
  const { status } = moderationState;

  if (!MODERATION_DISPLAY_MAP[status]) {
    return null;
  }

  const displayData = { ...MODERATION_DISPLAY_MAP[status] };

  return displayData;
};

const SUBMITTABLE_STATUSES = [
  MODERATION_STATUS_DRAFT,
  MODERATION_STATUS_REJECTED,
];

export const isEntitySubmittable = (entity) => {
  const { moderationState } = entity;

  return (
    !!entity.id && SUBMITTABLE_STATUSES.indexOf(moderationState.status) >= 0
  );
};

export const entityNeedsReviewByUser = (profileInfo, entity) => {
  if (!entity || !entity.moderationState) {
    return false;
  }

  const { moderationState } = entity;
  const isPartnerUser = Partner.hasRole(
    profileInfo,
    Partner.PARTNER_USER_ROLES
  );
  const isSuperUser = Partner.hasRole(profileInfo, Partner.SUPER_USER_ROLES);

  const needsPartnerUserReview =
    isPartnerUser && moderationState.status === MODERATION_STATUS.REJECTED;
  const needsSuperUserReview =
    isSuperUser && moderationState.status === MODERATION_STATUS.SUBMITTED;

  return needsPartnerUserReview || needsSuperUserReview;
};

const rejectedFieldsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    fieldName: PropTypes.string,
  })
);

export const ModerationStatePropType = PropTypes.shape({
  status: PropTypes.string,
  rejectedFields: rejectedFieldsPropType,
  lastActivity: PropTypes.shape({
    type: PropTypes.string,
    date: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      role: PropTypes.string,
    }),
    comment: PropTypes.string,
    rejectedFields: rejectedFieldsPropType,
  }),
});

export const ModeratedEntityPropType = PropTypes.shape({
  moderationState: ModerationStatePropType.isRequired,
});

export const getEmptyModerationState = () => ({
  lastActivity: null,
  rejectedFields: [],
  status: MODERATION_STATUS.DRAFT,
});

export const getEmptyModerationActivity = () => ({
  comment: "",
  rejectedFields: [],
  user: {
    firstName: "",
    lastName: "",
    role: Partner.USER_ROLES.ADMINISTRATOR,
  },
});
