import PropTypes from "prop-types";

import sharedPropTypes from "../../../shared/propTypes";

export const MODERATION_ACTIVITY_TYPE = {
  APPROVE: "APPROVE",
  COMMENT: "COMMENT",
  REJECT: "REJECT",
  SAVE_DRAFT: "SAVE_DRAFT",
  SUBMIT: "SUBMIT",
};

export const ModerationActivityPropType = PropTypes.shape({
  comment: PropTypes.string,
  date: PropTypes.string,
  rejectedFields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  user: sharedPropTypes.profileInfo,
});

export const ModerationWorkflowPropType = PropTypes.shape({
  currentState: PropTypes.string,

  activity: PropTypes.arrayOf(ModerationActivityPropType),
});

export const getActivityTextMap = (entityType) => ({
  [MODERATION_ACTIVITY_TYPE.APPROVE]: `approved ${entityType}`,
  [MODERATION_ACTIVITY_TYPE.COMMENT]: "left a comment",
  [MODERATION_ACTIVITY_TYPE.REJECT]: `rejected ${entityType}`,
  [MODERATION_ACTIVITY_TYPE.SAVE_DRAFT]: `saved a new draft`,
  [MODERATION_ACTIVITY_TYPE.SUBMIT]: `submitted ${entityType}`,
});
