import { subdivision } from "iso-3166-2";

export const getStateName = (isoName) => isoName && subdivision(isoName).name;
export const getStateAbbreviation = (isoName) =>
  isoName && subdivision(isoName).regionCode;
export const transformIsoName = (isoName) => ({
  value: isoName,
  label: getStateName(isoName),
});

export const renderStatesList = (states, displayCount = 3) => {
  if (states.length <= displayCount) {
    return states.map(getStateName).join(", ");
  }

  const displayStates = states
    .slice(0, displayCount)
    .map(getStateName)
    .join(", ");
  const remainingCount = states.length - displayCount;

  return `${displayStates} and ${remainingCount} more`;
};
