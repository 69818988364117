import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import sharedPropTypes from "../shared/propTypes";

import "./Page.css";

export const Page = ({ className, children }) => {
  const classes = cx("page", { [className]: className });
  return <div className={classes}>{children}</div>;
};

Page.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
};

export default Page;
