import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import sharedPropTypes from "../shared/propTypes";

import "./Sidebar.css";

export const Sidebar = ({ className, children }) => {
  return <div className={cn("sidebar", className)}>{children}</div>;
};

Sidebar.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
};

export default Sidebar;
