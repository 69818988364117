import React from "react";
import { hot } from "react-hot-loader";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import { store } from "../Store";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Layout } from "./components/Layout";
import WithUserInfo from "./containers/WithUserInfo";
import NewReleaseModal from "./releases/containers/NewReleaseModal";
import Routes from "./router/containers/Routes";

import "./styles/index.css";

const ErrorBoundaryFallback = () => (
  <div className="error-boundary__fallback">
    <h2>Uh oh!</h2>
    <p>There was an unexpected error!</p>
    <p>
      Please{" "}
      <a href="#" onClick={() => window.location.reload()}>
        refresh the page
      </a>{" "}
      and try again while we look into it.
    </p>
  </div>
);

const queryClient = new QueryClient();

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <WithUserInfo>
            <Layout>
              <Routes />
            </Layout>
          </WithUserInfo>
          <NewReleaseModal />
        </HashRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </ErrorBoundary>
);

export default hot(module)(App);
