export const SAVE_VERBS = {
  SAVE: {
    PRESENT: "saving",
    PAST: "saved",
  },
  ARCHIVE: {
    PRESENT: "archiving",
    PAST: "archived",
  },
  UNARCHIVE: {
    PRESENT: "unarchiving",
    PAST: "unarchived",
  },
};

export const STRAINS_TABLE_KEYS = {
  ARCHIVED: "archivedStrains",
  MODERATION: "moderationStrains",
  STRAINS: "strains",
  STRAINS_WITH_MODERATION: "strainsWithModeration",
};

export const ARCHIVE_FILTER = {
  ARCHIVED: "ARCHIVED",
  NOT_ARCHIVED: "NOT_ARCHIVED",
  NO_FILTER: "NO_FILTER",
};

export const MODERATION_FILTER = {
  ATTENTION_NEEDED_FROM_ADMIN: "ATTENTION_NEEDED_FROM_ADMIN",
  ATTENTION_NEEDED_FROM_PARTNER: "ATTENTION_NEEDED_FROM_PARTNER",
  ATTENTION_NOT_NEEDED: "ATTENTION_NOT_NEEDED",
  NO_FILTER: "NO_FILTER",
};

export const USER_ROLES = {
  PARTNER_ADMIN: "PARTNER_ADMIN",
  PARTNER_USER: "PARTNER_USER",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const DEFAULT_PAGE_SIZES = {
  MODERATION_PAGE_SIZE: 5,
  PARTNER_PAGE_SIZE: 15,
  STRAIN_PAGE_SIZE: 30,
};
