import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

const IGNORE_ERRORS = ["INVALID_LOGIN"];

const bugsnagClient = Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  enabledReleaseStages: ["staging", "production"],
  plugins: [new BugsnagPluginReact(React)],
  beforeSend: (report) => {
    const messageContainsIgnorableError = IGNORE_ERRORS.some(
      (errorCode) => report.errorMessage.indexOf(errorCode) >= 0
    );

    if (messageContainsIgnorableError) {
      report.ignore();
    }
  },
});

export default bugsnagClient;
