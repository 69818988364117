import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import sharedPropTypes from "../shared/propTypes";

const RoleRoute = ({
  component,
  hasRole,
  profileInfo,
  redirectTo,
  ...props
}) => {
  const Component = component;

  return (
    <Route
      {...props}
      render={(routeRenderProps) => {
        if (hasRole) {
          return <Component {...routeRenderProps} />;
        }

        const to =
          typeof redirectTo === "function"
            ? redirectTo(profileInfo)
            : redirectTo;

        return <Redirect to={to} />;
      }}
    />
  );
};

RoleRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  hasRole: PropTypes.bool.isRequired,
  profileInfo: sharedPropTypes.profileInfo,
  redirectTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    .isRequired,
};

export default RoleRoute;
