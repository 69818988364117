import bugsnagClient from "src/bugsnag";
import { ACCESS_TOKEN, PUBLIC_ROUTES } from "src/constants";
import history from "src/history";

import { resetState, setState } from "../../../../reducer/RootReducer";

import apiRequest, { tokenIsValid } from "../../../api/apiRequest";
import { fetchHasViewedLatestRelease } from "../../../releases/actions/ReleasesActions";
import { getDefaultRouteForUser } from "../../../router/selectors/RouterSelectors";
import { flashError } from "../../../shared/flash/actions/FlashActions";
import { hasRole, USER_ROLES } from "../../../shared/partner/models/Partner";

let checkTokenValidityInterval;

export const fetchUserInfo =
  ({ redirectToDefaultRoute }, bugsnag = bugsnagClient) =>
  async (dispatch) => {
    dispatch(setState("isFetchingUserInfo", true));
    const response = await apiRequest({
      dispatch,
      url: "/api/v1/partner-users/me",
    });

    if (!response.ok) {
      dispatch(setState("isFetchingUserInfo", false));
      return response;
    }

    const { passwordHash, ...profile } = response.data;
    bugsnag.user = profile;

    if (hasRole(profile, USER_ROLES.OPERATOR)) {
      dispatch(resetState());
      dispatch(
        flashError(
          "You do not have permission to access the Connect Portal. Please contact your administrator."
        )
      );
      history.replace(PUBLIC_ROUTES.login);
      return response;
    }

    await dispatch(fetchHasViewedLatestRelease());

    dispatch(setState("profileInfo", profile));
    dispatch(setState("isFetchingUserInfo", false));

    if (redirectToDefaultRoute) {
      const defaultRoute = getDefaultRouteForUser(profile);

      if (defaultRoute) {
        history.replace(defaultRoute);
      }
    }

    return response;
  };

export const onLoginSuccess =
  ({ fetchUserInfoAction = fetchUserInfo, token }) =>
  (dispatch) => {
    localStorage.setItem(ACCESS_TOKEN, token);
    dispatch(setState("sessionToken", token));
    dispatch(
      fetchUserInfoAction({
        redirectToDefaultRoute: true,
      })
    );

    startTokenCheckInterval(token, dispatch);
  };

const startTokenCheckInterval = (token, dispatch) => {
  checkTokenValidityInterval = setInterval(() => {
    if (!tokenIsValid(token)) {
      dispatch(logout());
    }
  }, 1000 * 60);
};

export const postLogin = (email, password) => async (dispatch) => {
  let url = "/api/v1/login";

  const response = await apiRequest({
    data: JSON.stringify({ email, password }),
    dispatch,
    method: "POST",
    url,
  });

  return response;
};

export const logout = () => async (dispatch) => {
  await apiRequest({
    data: {},
    dispatch,
    method: "POST",
    url: "/api/v1/logout",
  });
  clearInterval(checkTokenValidityInterval);
  localStorage.clear();
  dispatch(resetState());

  history.push(PUBLIC_ROUTES.login);
};
