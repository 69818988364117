import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import Modal from "../../components/Modal";

import { ReleasePropType } from "../models/Release";

const NewReleaseModal = ({ isOpen, latestRelease, postViewedRelease }) => {
  const handleRequestClose = () => {
    postViewedRelease(latestRelease.version);
  };

  return (
    <sc.Modal isOpen={isOpen} onRequestClose={handleRequestClose}>
      <sc.Content>
        <sc.Title>What&apos;s New In Version {latestRelease.version}</sc.Title>
        <ReactMarkdown source={latestRelease.body} />
      </sc.Content>
    </sc.Modal>
  );
};

NewReleaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  latestRelease: ReleasePropType.isRequired,
  postViewedRelease: PropTypes.func.isRequired,
};

export default NewReleaseModal;

const sc = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Modal: styled(Modal)`
    && {
      height: 600px;
      margin: auto;
      width: 600px;
      z-index: 999;
    }
  `,

  Title: styled.div`
    font-size: 38px;
    font-weight: 500;
    margin: 40px 0 40px;
    text-align: center;
    text-transform: capitalize;
  `,
};
