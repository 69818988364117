import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import sharedPropTypes from "../shared/propTypes";

import "./Panel.css";

export const Panel = ({ className, children }) => {
  const classes = cx("panel", { [className]: className });
  return <div className={classes}>{children}</div>;
};

Panel.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
};

export default Panel;
