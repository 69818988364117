import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Page from "../../components/Page";
import Panel from "../../components/Panel";
import Sidebar from "../../components/Sidebar";
import SidebarList from "../../components/SidebarList";
import sharedPropTypes from "../../shared/propTypes";

import { ReleasePropType } from "../models/Release";

const { ListItem } = SidebarList;
const { Title, Subtitle } = ListItem;

const ReleasesPage = ({ latestRelease, releases, routes }) => {
  return (
    <Page className="releases-page">
      <Sidebar>
        <SidebarList>
          {releases.map((release) => (
            <ReleaseListItem
              key={release.version}
              release={release}
              routes={routes}
            />
          ))}
        </SidebarList>
      </Sidebar>
      <sc.Panel>
        <ReactMarkdown source={latestRelease.body} />
      </sc.Panel>
    </Page>
  );
};

ReleasesPage.propTypes = {
  latestRelease: ReleasePropType.isRequired,
  routes: sharedPropTypes.routes.isRequired,
  releases: PropTypes.arrayOf(ReleasePropType).isRequired,
};

const ReleaseListItem = ({ release, routes }) => {
  const { date, version } = release;
  const dateString = format(parseISO(date), "MMMM d, yyyy");

  return (
    <ListItem>
      <Link to={routes.release(version)}>
        <Title>{dateString}</Title>
        <Subtitle>{version}</Subtitle>
      </Link>
    </ListItem>
  );
};

ReleaseListItem.propTypes = {
  release: ReleasePropType.isRequired,
  routes: sharedPropTypes.routes.isRequired,
};

const sc = {
  Panel: styled(Panel)`
    background-color: var(--white-two);
    padding: 0 40px;
  `,
};

export default ReleasesPage;
