import { sortBy } from "lodash";
import { createSelector } from "reselect";

import { createModerationActivitySelector } from "../../../shared/moderation/selectors/ModerationActivitySelectors";
import * as Partner from "../../../shared/partner/models/Partner";

const getPartnersMap = (state) => state.partners;

const getPartnersFilter = (state) => state.partnersFilter;

const getPartnerTable = (state, { tableKey }) => state.partnerTables[tableKey];

export const getAllPartners = createSelector(getPartnersMap, (partners) =>
  Object.values(partners)
);

const getSortedPartners = createSelector(
  [getAllPartners, getPartnerTable],
  (partners, partnerTable) => {
    if (partnerTable) {
      const sortedPartners = sortBy(partners, [partnerTable.sortBy]);

      return partnerTable.asc ? sortedPartners : sortedPartners.reverse();
    }

    return sortBy(partners, ["name"]);
  }
);

export const getFilteredPartners = createSelector(
  getSortedPartners,
  getPartnersFilter,
  (partners, partnersFilter) => {
    return partners
      .filter((partner) =>
        partnerMatchesSubsetFilter(partner, partnersFilter.subset)
      )
      .filter((partner) =>
        partnerMatchesRegionFilter(partner, partnersFilter.region)
      )
      .filter((partner) =>
        partnerMatchesGeoStateFilter(partner, partnersFilter.geoState)
      );
  }
);

const partnerMatchesSubsetFilter = (partner, subset) => {
  switch (subset) {
    case Partner.SUBSETS.ACTIVE:
      return partner.isArchived === false;

    case Partner.SUBSETS.NEEDS_APPROVAL:
      return Partner.partnerIsInModeration(partner);

    case Partner.SUBSETS.ARCHIVED:
      return partner.isArchived === true;

    case Partner.SUBSETS.ALL:
    default:
      return true;
  }
};

const partnerMatchesRegionFilter = (partner, region) => {
  return !region || region === partner.region;
};

const partnerMatchesGeoStateFilter = (partner, geoState) => {
  if (!geoState) {
    return true;
  }

  return partner.states.indexOf(geoState) >= 0;
};

export const getActivePartners = createSelector(getAllPartners, (partners) => {
  return partners.filter((partner) => partner.isArchived === false);
});

export const getArchivedPartners = createSelector(
  getAllPartners,
  (partners) => {
    return partners.filter((partner) => partner.isArchived === true);
  }
);

export const getPartnersInModeration = createSelector(
  getAllPartners,
  (partners) => {
    return partners.filter(Partner.partnerIsInModeration);
  }
);

export const getFilteredPartnersInModeration = createSelector(
  getFilteredPartners,
  (partners) => {
    return partners.filter(Partner.partnerIsInModeration);
  }
);

export const getFilteredPartnersNotInModeration = createSelector(
  getFilteredPartners,
  (partners) => {
    return partners.filter(
      (partner) => !Partner.partnerIsInModeration(partner)
    );
  }
);

export const getImpersonatedPartnerId = (state) => state.impersonatedPartnerId;

export const getImpersonatedPartner = createSelector(
  getImpersonatedPartnerId,
  getPartnersMap,
  (partnerId, partners) => {
    return partners[partnerId];
  }
);

const hasPartnerAdminRole = (role) =>
  Partner.PARTNER_ADMIN_USER_ROLES.indexOf(role) >= 0;

export const profileRoleSelector = (state) =>
  state.profileInfo && state.profileInfo.role;

export const isPartnerAdminSelector = createSelector(
  profileRoleSelector,
  hasPartnerAdminRole
);

export const isPartnerSuperUserSelector = createSelector(
  profileRoleSelector,
  (role) => Partner.SUPER_USER_ROLES.indexOf(role) >= 0
);

export const profileEmailSelector = (state) =>
  state.profileInfo && state.profileInfo.email;

export const getPartnerModerationActivity = createModerationActivitySelector(
  (state) => [...state.PartnerPage.partnerModerationActivity].reverse()
);

export const getIsFetchingPartnerUserInfo = (state) => state.isFetchingUserInfo;

export const getIsPartnerUserSignedIn = (state) => !!state.sessionToken;
