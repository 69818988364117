import { connect } from "react-redux";

import * as Partner from "../../app/shared/partner/models/Partner";

import RoleRoute from "../components/RoleRoute";

const mapStateToProps = (state, ownProps) => {
  const roles = ownProps.roles || [ownProps.role];

  return {
    hasRole: Partner.hasRole(state.profileInfo, roles),
    profileInfo: state.profileInfo,
  };
};

export default connect(mapStateToProps)(RoleRoute);
