import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FaTimes } from "react-icons/fa";
import ReactModal from "react-modal";

import sharedPropTypes from "../shared/propTypes";

import "./Modal.css";

export const Modal = ({ className, children, showCloseIcon, ...props }) => {
  return (
    <ReactModal
      className={cn("modal", className)}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      {...props}
    >
      {showCloseIcon && (
        <div className="modal__close-icon" onClick={props.onRequestClose}>
          <FaTimes />
        </div>
      )}
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
  onRequestClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

Modal.defaultProps = {
  showCloseIcon: true,
};

export default Modal;
