import PropTypes from "prop-types";
import React from "react";

import PaxConnectLogo from "../images/pax-connect-logo.svg";
import sharedPropTypes from "../shared/propTypes";

import "./LogoPage.css";

export const LogoPage = ({ className, children }) => (
  <div className={`logo-page ${className}`}>
    <div className="logo-page__logo-container">
      <PaxConnectLogo className="logo-page__logo" alt="PAX Connect logo" />
    </div>
    {children}
  </div>
);

LogoPage.propTypes = {
  className: PropTypes.string,
  children: sharedPropTypes.children,
};
