import cx from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setState } from "../../reducer/RootReducer";

import "./FlashMessage.css";

const FLASH_MESSAGE_DEFAULT_TIMEOUT = 5000;

export const FlashMessage = ({ clearFlashMessage, flashMessage }) => {
  useEffect(() => {
    if (flashMessage) {
      setTimeout(
        clearFlashMessage,
        flashMessage.timeout || FLASH_MESSAGE_DEFAULT_TIMEOUT
      );
    }
  }, [clearFlashMessage, flashMessage]);

  if (!flashMessage) {
    return null;
  }

  const { type, message } = flashMessage;

  return (
    <div
      className={cx("flash-message", `flash-message--${type}`)}
      data-testid="flash-message"
    >
      {message}
    </div>
  );
};

export const FlashMessagePropType = PropTypes.shape({
  message: PropTypes.string,
  timeout: PropTypes.string,
  type: PropTypes.string,
});

FlashMessage.propTypes = {
  clearFlashMessage: PropTypes.func.isRequired,
  flashMessage: FlashMessagePropType,
};

const mapStateToProps = (state) => ({
  flashMessage: state.flashMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearFlashMessage: () => dispatch(setState("flashMessage", null)),
});

export const FlashMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessage);
