import { connect } from "react-redux";

import { getDefaultRouteForUser } from "../../../router/selectors/RouterSelectors";

import NotFoundPage from "../components/NotFoundPage";

const mapStateToProps = (state) => ({
  defaultRoute: getDefaultRouteForUser(state.profileInfo),
});

export default connect(mapStateToProps)(NotFoundPage);
