import axios from "axios";
import jwtDecode from "jwt-decode";

import { logout } from "../../app/pages/login/actions/UserActions";
import {
  flashError,
  flashWarning,
} from "../../app/shared/flash/actions/FlashActions";
import bugsnagClient from "../../bugsnag";
import { ACCESS_TOKEN } from "../../constants";

import fetchCache, {
  clearKeysForApiPath,
  standardizeApiPath,
} from "./fetchCache";

const apiRequest = async ({ dispatch, ...options }) => {
  options.baseURL = options.baseURL || PARTNER_SERVICE_URL;
  options.headers = withStandardHeaders(options.headers);

  try {
    const isGetRequest = !options.method || options.method === "GET";
    const apiPath = standardizeApiPath(options.url);

    let response;

    if (
      USE_FETCH_CACHE &&
      options.cache &&
      isGetRequest &&
      fetchCache.peek(apiPath)
    ) {
      response = Promise.resolve(fetchCache.get(apiPath));
    } else {
      response = await axios({
        ...options,
        url: apiPath,
        withCredentials: true,
      });

      if (USE_FETCH_CACHE) {
        if (options.cache && isGetRequest) {
          fetchCache.set(apiPath, response);
        } else {
          clearKeysForApiPath(apiPath);
        }
      }
    }

    response.ok =
      !response.data || (!response.data.error && !response.data.errors);
    return response;
  } catch (e) {
    if (e.response) {
      // Request was made and response has a status other than 2xx.
      e.response.ok = false;

      if (e.response.status >= 500) {
        dispatch(
          flashError("There was an unexpected error! Please try again.")
        );
      }

      if (e.response.status === 403) {
        dispatch(flashWarning("Session expired. Please log in again."));
        dispatch(logout());
      }

      return e.response;
    } else if (e.request) {
      // Request was made but no response was received.
      dispatch(
        flashWarning(
          "No response received. Please check your network connection and try again."
        )
      );

      return { ok: false };
    } else {
      // Something happened in setting up the request.
      bugsnagClient.notify(e);

      dispatch(flashError("There was an unexpected error. Please try again."));

      return { ok: false };
    }
  }
};

export default apiRequest;

export const apiXhrRequest = (options) => {
  return new Promise((resolve, reject) => {
    // Initialize the request.
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open(options.method, PARTNER_SERVICE_URL + options.url);

    // Attach listeners.
    if (xhr.upload && options.onProgress) {
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          options.onProgress(percentComplete, event);
        }
      });
    }
    xhr.addEventListener("load", () => {
      if (xhr.status >= 400) {
        throw JSON.parse(xhr.responseText);
      }

      resolve(JSON.parse(xhr.responseText));
    });
    xhr.upload.addEventListener("error", reject);
    xhr.upload.addEventListener("abort", reject);

    // Set headers.
    options.headers = withStandardHeaders(options.headers);
    Object.keys(options.headers).forEach((headerName) => {
      xhr.setRequestHeader(headerName, options.headers[headerName]);
    });

    // Send the request.
    xhr.send(options.body);
  });
};

export const tokenIsValid = (jwtToken) => {
  if (!jwtToken) return false;

  const decodedToken = jwtDecode(jwtToken);
  const tokenExpirationEpochTime = decodedToken.exp * 1000;

  return tokenExpirationEpochTime > Date.now();
};

const withStandardHeaders = (headers) => {
  headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...headers,
  };

  // Remove Content-Type when it's undefined. The browser needs to set it when
  // uploading form data so that the header can include the form data boundary.
  if (headers["Content-Type"] === undefined) {
    delete headers["Content-Type"];
  }

  // Set Authorization header after header overrides have been applied.
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  return headers;
};
