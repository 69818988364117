import { constant, updateIn } from "updeep";

import { initialState } from "./InitialState";

export const SET_STATE = "SET_STATE";
export const MERGE_STATE = "MERGE_STATE";
export const RESET_STATE = "RESET_STATE";

export const hashifyArray = (arr) =>
  arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

export function setState(key, payload) {
  return {
    type: SET_STATE,
    key,
    payload,
  };
}

export function mergeState(key, payload) {
  return {
    type: MERGE_STATE,
    key,
    payload,
  };
}

export function resetState() {
  return { type: RESET_STATE };
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATE:
      return updateIn(action.key, constant(action.payload), state);
    case MERGE_STATE:
      return updateIn(action.key, action.payload, state);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
